<template>
	<input type="text" 
		:id="id" 
		:name="getName" 
		:class="['c-input-text', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		:maxlength="maxlength"
		:disabled="disabled"
		:value="modelValue"
		@input="onInput"
		data-lpignore="true"
	>
	<!-- //TODO: Remove @change and @blur -->

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
//Note: `data-lpignore="true"` to stop LastPass icon
import allFormElementPropsMixin from "./all-form-element-props-mixin";


export default {

	mixins: [allFormElementPropsMixin],

	emits: ["change", "update:modelValue"],

	props: {
		
		//TODO: Obsolete. Remove.
		initialValue: {
			type: [String, Number],
			default: ""
		},

		
		placeholder: {
			type: String,
			default: ""
		},

		
		maxlength: {
			type: Number,
			default: -1
		},

		isBold: {
			type: Boolean,
			default: false,
		},


		preventFormSubmitOnEnter: {
			type: Boolean,
			default: false,
			required: false,
		},



		//TODO: New with Vue 3
        modelValue: {
            type: [String, Number],
            default: '',
            required: false
        },



		type: {
			type: String,
			default: "native"
		},

	},





	data()
	{
		return {
			value: ""
		};
	},

	mounted()
	{
		//TODO: Obsolete. Remove.
		//this.value = this.initialValue;
	},
	
	//TODO: Do I seriously need to do this for each control. Why? Because mounted doesn't work, probably because we're getting a value from the API in the parent and it needs to flow through to here, after this control is mounted.
	watch: {
		//TODO: Obsolete. Remove.
		// initialValue: function(to, from)
		// {
		// 	this.value = this.initialValue;
		// }
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onInput(event)
		{
			const value = event.target.value;
			// console.log("input-text.onInput(): ", value);

			// this.$emit('input', $event.target.value);
			this.$emit('change', value);

			this.$emit('update:modelValue', event.target.value);
		},

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-input-text 
	{
		width: 100%;
	}
</style>

<style lang="scss">
	
	.c-input-text.el-override-bold 
	{
		.el-input__inner {
			font-weight: bold;		
		}
	}
</style>


<template>
	<c-grid>
		<c-form>
			<c-row>
				<c-col id="form-left-col" css-class="left-column" :cols="leftColumnSize">
					<h4 class="column-label column-label-left" v-html="meta.columns.left.label"></h4>

					<template v-for="(value, fieldId) in getLeftColumnFields" :key="fieldId">
						<!-- Input fields -->
						<c-form-item 
							:label-cols="getLeftColumnLabelCols" 
							:input-cols="getLeftColumnInputCols" 
							:id="fieldId" 
							:name="fieldId"
							:is-required="isRequired(fieldId)"
							v-if="isInputField(fieldId)"
						>
							<template v-slot:label>{{ getLabel(fieldId) }}</template>
							<template v-slot:input>
								<c-input-text :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'text'" @change="onChange($event, fieldId)"></c-input-text>
								<c-input-textarea :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'textarea'" @change="onChange($event, fieldId)"></c-input-textarea>
								<c-input-number :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'number'" @change="onChange($event, fieldId)"></c-input-number>
								<c-input-checkbox :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'checkbox'"  @change="onChange($event, fieldId)"></c-input-checkbox>

								<c-input-date :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'date'" @change="onChange($event, fieldId)"></c-input-date>
								<c-input-time :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'time'" @change="onChange($event, fieldId)"></c-input-time>
								<c-input-email :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'email'" @change="onChange($event, fieldId)"></c-input-email>
								<c-input-text :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'phone'" @change="onChange($event, fieldId)"></c-input-text>

								<c-input-select :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'select'" @change="onChange($event, fieldId)"></c-input-select>
								<c-input-select :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'select-multi'" :multiple="true" @change="onChange($event, fieldId)"></c-input-select>

								<c-input-radio-set :id="fieldId" :set-name="'set-' + fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'radio-set'" @change="onChange($event, fieldId)"></c-input-radio-set>

								<c-readonly-field :id="fieldId" :model-value="state[fieldId]" v-if="getControlType(fieldId) == 'read-only'"></c-readonly-field>

								<set-and-show-address v-if="getControlType(fieldId) == 'address'" 
									:id="fieldId"
									:address="state[fieldId]" 
									:showCountryOther="getValueOrDefault(meta[fieldId].showCountryOther, false)"
									:showCoordinates="getValueOrDefault(meta[fieldId].showCoordinates, false)"
									:showActivationRadius="getValueOrDefault(meta[fieldId].showActivationRadius, false)"
									@set="(data) => onSetAddress(data, fieldId)">
								</set-and-show-address>

								
								<!-- Check if a slot is defined for the column -->
								<!-- Set type="custom" for this case -->
								<slot :name="fieldId" v-bind:item="state"></slot>

								<c-info-text v-if="meta[fieldId].infoText">
									<div class="mb-1">{{ meta[fieldId].infoText }}</div>
								</c-info-text>

								<c-field-validation-result :message="meta[fieldId].validationMessage"></c-field-validation-result>
							</template>
						</c-form-item>

						<!-- Non input fields -->
						<div v-if="getControlType(fieldId) == 'visual-divider'">
							<hr />
						</div>
					</template>
				</c-col>


				<c-col id="form-right-col" css-class="right-column" :cols="rightColumnSize">
					<h4 class="column-label column-label-right" v-html="meta.columns.right.label"></h4>

					<template v-for="(value, fieldId) in getRightColumnFields" :key="fieldId">
						<!-- Input fields -->
						<c-form-item 
							:label-cols="getRightColumnLabelCols" 
							:input-cols="getRightColumnInputCols" 
							:id="fieldId" 
							:name="fieldId"
							:is-required="isRequired(fieldId)"
							v-if="isInputField(fieldId)"
						>
							<template v-slot:label>{{ getLabel(fieldId) }}</template>
							<template v-slot:input>
								<c-input-text :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'text'" @change="onChange($event, fieldId)"></c-input-text>
								<c-input-textarea :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'textarea'" @change="onChange($event, fieldId)"></c-input-textarea>
								<c-input-number :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'number'" @change="onChange($event, fieldId)"></c-input-number>
								<c-input-checkbox :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'checkbox'"  @change="onChange($event, fieldId)"></c-input-checkbox>

								<c-input-date :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'date'" @change="onChange($event, fieldId)"></c-input-date>
								<c-input-time :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'time'" @change="onChange($event, fieldId)"></c-input-time>
								<c-input-email :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'email'" @change="onChange($event, fieldId)"></c-input-email>
								<c-input-text :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" v-if="getControlType(fieldId) == 'phone'" @change="onChange($event, fieldId)"></c-input-text>

								<c-input-select :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'select'" @change="onChange($event, fieldId)"></c-input-select>
								<c-input-select :id="fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'select-multi'" :multiple="true" @change="onChange($event, fieldId)"></c-input-select>

								<c-input-radio-set :id="fieldId" :set-name="'set-' + fieldId" :disabled="meta[fieldId].disabled" v-model="state[fieldId]" :initial-value="state[fieldId]" :options="meta[fieldId].list" v-if="getControlType(fieldId) == 'radio-set'" @change="onChange($event, fieldId)"></c-input-radio-set>

								<c-readonly-field :id="fieldId" :model-value="state[fieldId]" v-if="getControlType(fieldId) == 'read-only'"></c-readonly-field>

								<set-and-show-address v-if="state[fieldId] && getControlType(fieldId) == 'address'" 
									:id="fieldId"
									:address="state[fieldId]" 
									:showCountryOther="getValueOrDefault(meta[fieldId].showCountryOther, false)"
									:showCoordinates="getValueOrDefault(meta[fieldId].showCoordinates, false)"
									:showActivationRadius="getValueOrDefault(meta[fieldId].showActivationRadius, false)"
									@set="(data) => onSetAddress(data, fieldId)">
								</set-and-show-address>


								<!-- Check if a slot is defined for the column -->
								<!-- Set type="custom" for this case -->
								<slot :name="fieldId" v-bind:item="state"></slot>

								<c-info-text v-if="meta[fieldId].infoText">
									<div class="mb-1">{{ meta[fieldId].infoText }}</div>
								</c-info-text>

								<c-field-validation-result :message="meta[fieldId].validationMessage"></c-field-validation-result>
							</template>
						</c-form-item>


						<!-- Non input fields -->
						<div v-if="getControlType(fieldId) == 'visual-divider'">
							<hr />
						</div>
					</template>
				</c-col>
			</c-row>
			

			<!-- Actions area. We want to alignunder the input fields -->
			<c-row class="actions-row">
				<!-- :cols="actionsColumnSize". Disabled for now -->
				<c-col 
					:cols="leftColumnSize"
					css-class="text-left">

					<c-form-item 
						:label-cols="getLeftColumnLabelCols" 
						:input-cols="getLeftColumnInputCols" 
						id="actions" 	
					>
						<template v-slot:label></template>
						<template v-slot:input>
							<c-button-confirm id="save-button" @click="onSave" v-if="showSave" size="large" class="mr-1">
								<!-- <template v-slot:icon></template> -->
								<template v-slot:text>{{ saveButtonText }}</template>
							</c-button-confirm>

							<c-button-danger id="delete-button" @click="onDelete" v-if="showDelete" size="large" class="mr-1">
								<!-- <template v-slot:icon></template> -->
								<template v-slot:text>{{ deleteButtonText }}</template>
							</c-button-danger>

							<c-button-danger id="archive-button" @click="onArchive" v-if="showArchive" size="large" class="mr-1">
								<!-- <template v-slot:icon></template> -->
								<template v-slot:text>{{ archiveButtonText }}</template>
							</c-button-danger>
							
							<c-button-cancel id="cancel-button" @click="onCancel" v-if="showCancel" size="large" :disabled="!hasStateChanged" class="mr-1">
								<!-- <template v-slot:icon></template> -->
								<template v-slot:text>{{ cancelButtonText }}</template>
							</c-button-cancel>

							<c-button-cancel id="reset-button" @click="onReset" v-if="showReset" size="large">
								<!-- <template v-slot:icon></template> -->
								<template v-slot:text>{{ resetButtonText }}</template>
							</c-button-cancel>

							<slot name="actions"></slot>

							<div v-if="1==2">
								<!-- 
									This is not working right. And it's a fucking pain in the ass to reset. 
									Come back to it at some pont, but if we want this message then have it in the host component.
								-->
								<span v-if="hasStateChanged" class="unsaved-changes-notificaiton">Unsaved changes</span>
							</div>
						</template>
					</c-form-item>
					
				</c-col>
			</c-row>
			

		</c-form>
	</c-grid>
</template>











<script setup>
import { reactive, onMounted, watch, computed, ref } from "vue";
import setAndShowAddress from '/components/set-and-show-address.vue';


const props = defineProps({
	/**
	 * Contains the data to display in each field of the form.  
	 * Key = The field name/key.  
	 * Value = The data value.
	 */
	data: Object,

	
	/**
	 * Meta information about each field in `props.data` to control the display of the form.  
	 * Each property maps to a 'fields' property.   
	 * Key = The field name/key. Corresponds to the Key of 'props.data'.
	 * Value = Each value is an object containing the fields meta inforamtion  
	 * - e.g. `{ label: "First Name", control: 'text', dataType: 'string', etc.}`
	 */
	meta: Object,

	//validationMessages: Object,
	//lists: Object,

	/**
	 * Show the "Save" button?
	 */
	showSave: {
		type: Boolean,
		required: false,
		default: true
	},
	saveButtonText: {
		type: String,
		required: false,
		default: "Save"
	},

	/**
	 * Show the "Cancel" button?
	 */
	showCancel: {
		type: Boolean,
		required: false,
		default: true
	},
	cancelButtonText: {
		type: String,
		required: false,
		default: "Discard changes"
	},


	/**
	 * Show the "Reset" button?
	 */
	showReset: {
		type: Boolean,
		required: false,
		default: false
	},
	resetButtonText: {
		type: String,
		required: false,
		default: "Reset"
	},
	
	/**
	 * Show the "Delete" button?
	 */
	showDelete: {
		type: Boolean,
		required: false,
		default: false
	},
	deleteButtonText: {
		type: String,
		required: false,
		default: "Delete"
	},

	/**
	 * Show the "Archive" button?
	 */
	showArchive: {
		type: Boolean,
		required: false,
		default: false
	},
	archiveButtonText: {
		type: String,
		required: false,
		default: "Archive"
	},

	//TODO: Not yet implemented
	/**
	 * Set to true to make the entire form read-only.
	 */
	readOnly: {
		type: Boolean,
		required: false,
		default: false
	},



	/**
	 * Set to `true` to trigger `resetStateChanges`().
	 * Needed for the CRM. I really wanted to do a direct call via a 'ref', but this is a 
	 * Vue3 composition API component and I'm calling from Vue2 and can't get an actual reference,
	 * and I have no fucking idea what's going on and I'm too fed up dealing with shit like this,
	 * so here comes yet another fucking hack. God I'm tired of this fucking industry.
	 */
	triggerResetStateChanges: {
		type: Boolean,
		required: false,
		default: false
	},



	
	/**
	 * Show the "Delete" button?
	 */
	showRightColumn: {
		type: Boolean,
		required: false,
		default: true
	},
	leftColumnSize: {
		type: [Number, null],
		required: false,
		default: 6
	},
	rightColumnSize: {
		type: [Number, null],
		required: false,
		default: 6
	},
	actionsColumnSize: {
		type: [Number, null],
		required: false,
		default: 12
	},

});

const emit = defineEmits(["change", "save", "cancel", "reset"]);

const simpleForm = ref(null);

const state = reactive({
	// Test: "Ay! 3",
});



/**
 * Make sure `state` data is updated when the field values are updated in the
 * parent component.
 * NOTE: Need to watch the whole "props", NOT just "props.data".
 */
watch(props, async (newData, oldData) => {
	//console.log("simple-form-renderer::watch(props.data): newData=", newData, " | props.data=", props.data);

	if (newData.triggerResetStateChanges == true)
	{
		console.log("simple-form-renderer:: watch(triggerResetStateChanges)");
		resetStateChanges();
		return;
	}

	copyDataToState();
})







onMounted(() => {
	//state.Test = "I'm a test";
	init();
});





function init() {
	//console.log("simple-form-renderer::init(): props.data=", props.data);
	copyDataToState();
}


/**
 * Copy data data from the `data` prop to the internal `state` data.
 * Why do this and not just have a `state` prop? I think it's so we dont'
 * mutate the prop values when updating the form. Instead, we mutate the 
 * `state` copy and then raise a `change` event for the parent to do
 * what it needs to update the data on that side.
 */
function copyDataToState()
{
	for (const fieldName in props.data) {
		state[fieldName] = props.data[fieldName];

		//console.log("fieldName=", fieldName, "state[" + fieldName + "]=", state[fieldName]);
	}
}










function getColumnFields(column)
{
	let columnFields = {};

	for (const fieldId in props.meta) 
	{
		// Reserved field. Skip.
		if (fieldId.toLocaleLowerCase() == "columns") continue;


		// Don't show it if it's not visible.
		if (props.meta[fieldId].visible != undefined && props.meta[fieldId].visible == false) continue;


		if (column == 'left')
		{
			if (!props.meta[fieldId].column || props.meta[fieldId].column == 'left')
			{
				// If no column is sepcified it's left by default, or, if explicity set to left.
				columnFields[fieldId] = state[fieldId];
			}
		}
		else
		{
			// The only other option is it's the right column
			if (props.meta[fieldId].column && props.meta[fieldId].column == 'right')
			{
				columnFields[fieldId] = state[fieldId];
			}
		}
	}

	//console.log("getColumnFields()", column, columnFields);

	return columnFields;
}



const getLeftColumnFields = computed(() => {
	return getColumnFields('left');
})

const getRightColumnFields = computed(() => {
	return getColumnFields('right')
})




const stateChanges = reactive({});

/**
 * Check and return true if any state values have been changed in the form.
 */
function calculateHasStateChanged()
{
	for (let prop in stateChanges)
	{
		if (stateChanges[prop] == "changed") return true;
	}

	return false;
}

function resetStateChanges()
{
	for (let prop in stateChanges)
	{
		stateChanges[prop] = "";
	}

	return false;
}


const hasStateChanged = computed(() => {
	return calculateHasStateChanged();
})





const getLeftColumnLabelCols = computed(() => {
	return props.meta.columns.left.labelCols ? props.meta.columns.left.labelCols : 3;
})
const getLeftColumnInputCols = computed(() => {
	return props.meta.columns.left.inputCols ? props.meta.columns.left.inputCols : 9;
})

const getRightColumnLabelCols = computed(() => {
	// console.log("props.meta.columns.right=", props.meta.columns.right);
	// console.log("props.meta.columns.right.labelCols=", props.meta.columns.right.labelCols);

	return props.meta.columns.right.labelCols ? props.meta.columns.right.labelCols : 3;
})
const getRightColumnInputCols = computed(() => {
	return props.meta.columns.right.inputCols ? props.meta.columns.right.inputCols : 9;
})



function getLabel(field) 
{
	if (!props.meta) return field; // Just the field ID/property name

	const fieldMeta = props.meta[field];

	if (!fieldMeta) return field; // Just the field ID/property name

	const label = fieldMeta.label;

	if (!label) return field; // Just the field ID/property name

	return label;
}





function getControlType(field) 
{
	var dataType = getDataType(field);
	var dataTypeControl = dataTypeToControlType(dataType);

	// return dataTypeControl;

	// No meta prop. Use the default.
	if (!props.meta) return dataTypeControl;

	const fieldMeta = props.meta[field];

	// No meta for the field. Use the default.
	if (!fieldMeta) return dataTypeControl;

	const control = fieldMeta.control;

	// No "control" meta value for the field. Use the default.
	if (!control) return dataTypeControl;

	// The specified value.
	return control;
}





function dataTypeToControlType(dataType) 
{
	if (dataType == "string") return "text";
	if (dataType == "number") return "number";
	if (dataType == "boolean") return "checkbox";
	if (dataType == "array") return "select";

	// Default to a plain text
	return "text";
}





function getDataType(field) 
{
	// https://www.w3schools.com/js/js_typeof.asp
	//typeof data[name] }} | {{ data[name].constructor.toString() }}

	// The field is on the object.
	if (!props.data[field]) return "";

	if (isArray(props.data[field])) return "array";

	return typeof props.data[field];

	function isArray(myArray) {
		return myArray.constructor === Array;
	}
}



/**
 * Return the `suppliedValue` if it is not `null` or `undefined`,
 * otherwise, return the `defaultValue`.
 */
function getValueOrDefault(suppliedValue, defaultValue)
{
	if (defaultValue == undefined || defaultValue == null)
	{
		// A default value is required in the method (and at the moment 'null' ain't going to cut it, unless we find a use case for it.)
		throw 'defaultValue(): No value for `getValueOrDefault` parameter provided.'
	}

	if (suppliedValue == undefined || suppliedValue == null)
	{
		// No supplied value, so return the default.
		return defaultValue;
	}

	return suppliedValue;
}



function isRequired(fieldId)
{
	if (!props.meta) return false;

	const fieldMeta = props.meta[fieldId];

	if (!fieldMeta) return false;

	const required = fieldMeta.required;

	if (!required) return false;

	return required;
}


function isInputField(fieldId)
{
	if (getControlType(fieldId) == 'visual-divider') return false;

	return true;
	// if (!props.meta) return false;

	// const fieldMeta = props.meta[field];

	// if (!fieldMeta) return false;

	// const required = fieldMeta.required;

	// if (!required) return false;

	// return required;
}










function onChange(controlData, fieldId) {
	// const value = event.target.value;
	// const name = event.target.name;

	//console.log("simple-form-renderer::onChange(): ", fieldId, " = ", controlData);

	const originalValue = props.data[fieldId];

	//console.log("simple-form-renderer::onChange(): data=", props.data);


	//console.log("simple-form-renderer::onChange(): ", originalValue, " --> ", controlData);

	stateChanges[fieldId] = "";
	if (originalValue != controlData)
	{
		// Field value has been changed		
		stateChanges[fieldId] = "changed";
	}




	if (controlData.target && controlData.srcElement)
	{
		// It's an `Event` object being returned from the underlying Element control most probably. Ignore it.
		return;
	}

	
	// console.log("changed(): event=", event);

	emit("change", {
		fieldId: fieldId,
		value: controlData,
	});
}







function onSetAddress(data, fieldId)
{
	// console.log("simple-form-renderer::onSetAddress(data)=", data);

	// if (!this.client.Address.ID)
	// {
	// 	// // No address record (or placeholder) previously set for the client.
	// 	// // We need to update the link between the address and the client now.
	// 	// this.client.Address.ID = data.AddressID;
	// 	// this.setClientAddress(data.AddressID, () => {
	// 	// 	this.getClient();
	// 	// });
	// 	// return;
	// }

	//this.getClient();

	//const fieldData = this.state[fieldId];

	// console.log("simple-form-renderer::onSetAddress(fieldData)=", fieldData);

	emit("change", {
		fieldId: fieldId,
		value: {
			AddressID: data.AddressID,
			Address: data.Address,
			Action: data.Action
		},
	});
}




function onSave()
{
	resetStateChanges();
	emit("save", {});
}



function onDelete()
{
	resetStateChanges();
	emit("delete", {});
}



function onArchive()
{
	resetStateChanges();
	emit("archive", {});
}


function onCancel()
{
	resetStateChanges();
	emit("cancel", {});
}



function onReset()
{
	resetStateChanges();
	emit("reset", {});
}




// https://vuejs.org/guide/components/events.html
// https://vuejs.org/guide/essentials/forms
</script>











<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.validation-text {
		color: red;
	}




	.column-label
	{
		font-size: 125%;	// Relative to the base (?) size.
	}



	.unsaved-changes-notificaiton
	{
		display: inline-block;
		padding-left: 10px;
		height: 10px;

		color: #990000;
		font-size: smaller;
		font-weight: 500;
	}
</style>

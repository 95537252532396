<template>
	<h2 class="dev-guide-component" v-if="type == 'dev-guide-component'">
		<slot></slot>
	</h2>

	<h1 class="page-title-v2" v-else-if="version == 2">
		<slot></slot>
	</h1>

	<h1 class="page-title" v-else-if="version == 1">
		<slot></slot>
	</h1>
	

	<h2 class="dev-guide-component mb-5" v-else>
		<slot></slot>
	</h2>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		/**
		 * What version of this componet. Controls styling.
		 */
		version: {
			type: Number,
			required: false,
			default: 2
		},


		/**
		 * What sort of heading are we showing
		 */
		type: {
			type: String,
			required: false,
			default: "standard" 	// "standard" | "dev-guide-component"
		},


		// text: {
		// 	type: String,
		// 	required: true
		// },
	},





	data()
	{
		return {
			
		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

.page-title
{
	font-family: $font-bold;

	font-size: 20px;
	color: #303133;
	margin-bottom: 0;
	font-weight: bold;

	font-family: CircularStd, Montserrat, sans-serif;
}



.page-title-v2
{
	font-family: $font-bold;

	font-size: 25px;
	margin-bottom: 0;
	font-weight: bold;

	// Compass gold
	color: $primary-yellow;

	font-family: CircularStd, Montserrat, sans-serif;
}




.dev-guide-component
	{
		// border-bottom: 1px solid #eeeeee;
		background-color: #c2e3f3;
		padding: 15px;
		border-radius: 5px;
	}
</style>
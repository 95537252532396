<template>
	<el-date-picker
		:id="id" 
		:name="getName" 
		:class="['c-input-date-range', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		
		v-model="value"
		
		type="daterange"
		range-separator="To"
		start-placeholder="Start date"
		end-placeholder="End date"
		format="DD MMM YYYY"
		value-format="YYYY-MM-DD"
		@change="onChangeEl"
		@input="onInputEl"
		@blur="onBlurEl"
		v-if="type == 'element'">
	</el-date-picker>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		initialValue: {
			type: Array,
			default: () => []
		},

		//TODO: Obsolete. Remove
		/**
		 * Set this from the parent page/component when wanting to 
		 * programatically update the selected value.
		 */
		updateValue: {
			type: Array,
		},


		type: {
			type: String,
			default: "element"
		},




		//TODO: New with Vue 3
        modelValue: {
            type: Array,
            default: () => [],
            required: true
        },




	},





	data()
	{
		return {
			value: []
		};
	},






	created()
	{
		// this.value = this.initialValue;	//TODO: Remove. Obsolete.
		this.value = this.modelValue;
	},
	mounted()
	{
		// this.value = this.initialValue;	//TODO: Remove. Obsolete.
		this.value = this.modelValue;
	},


	watch: {
		//TODO: Remove. Obsolete.
		// modelValue: function(to, from)
		// {
		// 	this.value = this.modelValue;
		//
		// 	console.log("c-input-date-range.watch(modelValue): from=", from, " | to=", to, " | this.modelValue=", this.modeValue, " | this.value=", this.value); 
		// },
		//
		// initialValue: function(to, from)
		// {
		// 	this.value = this.initialValue;
		//
		// 	//console.log("c-input-date-range.watch(initialValue): from=", from, " | to=", to, " | this.initialValue=", this.initialValue, " | this.value=", this.value); 
		// },
		//
		// updateValue: function(to, from)
		// {
		// 	this.value = this.updateValue;
		//
		// 	//console.log("c-input-date-range.watch(updateValue): from=", from, " | to=", to, " | this.updateValue=", this.updateValue, " | this.value=", this.value); 
		// },
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		},








		onInput($event)
		{
			this.$emit('input', $event.target.value);
		},


		onChange($event)
		{
			//this.$emit('change', $event.target.value);

			this.$emit('input', this.value);
			this.$emit('change', this.value);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		},





		onInputEl(value)
		{
			//console.log("c-input-date-range:onInputEl(): value=", value);

			//TODO: This zeroes out the bound model value.
			// console.log("onInputEl(): ", value);

			// this.$emit('input', value);


			//this.$emit('update:modelValue', value);
		},

		onBlurEl($event)
		{
			//console.log("c-input-date-range:onBlurEl(): $event=", $event);

			// console.log("onBlurEl(): ", $event);

			// this.$emit('blur', $event.target.value);
		},

		onChangeEl(value)
		{
			console.log("c-input-date-range:onChangeEl(): value=", value);
			console.log("c-input-date-range:onChangeEl(): this.value=", this.value);

			//this.$emit('input', this.value);
			this.$emit('change', this.value);

			//TODO: This isn't working ??? or is it?
			this.$emit('update:modelValue', this.value);
		},



	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
<template>

	<input type="range" 
			:id="id" :name="getName" 
			:class="['c-input-range', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:min="min" 
			:max="max" 
			:step="step" 
			:value="modelValue" 
			@change="onChange"
			@input="onInput"
			@blur="onBlur"
			>
		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		min: {
			type: Number,
			default: 0
		},
		

		max: {
			type: Number,
			default: 100
		},
		

		step: {
			type: Number,
			default: 1
		},


		//TODO: New with Vue 3
        modelValue: {
            type: [Number, String],
            default: 0,
            required: true
        },


		type: {
			type: String,
			default: "element"
		},



	},





	data()
	{
		return {
			
		};
	},






	mounted()
	{
		this.init();
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		init()
		{
			this.widthOnContainer = true;
		},

		
		onClick()
		{
			this.$emit('click', {});
		},


		onInput($event)
		{
			this.$emit('input', $event.target.value);


			this.$emit('update:modelValue', $event.target.value);
		},


		onChange($event)
		{
			this.$emit('change', $event.target.value);

			this.$emit('update:modelValue', $event.target.value);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
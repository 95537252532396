<template>
	<span class="simulate-form-control" style="display: inline-block;">
		<input 
			type="time" 
			:id="id" :name="getName" 
			:class="['c-input-time', 'c-input-time-native', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:disabled="disabled"
			:value="modelValue"
			@change="onChangeNative"
			@input="onInputNative"
			@blur="onBlurNative"
			v-if="1 == 1 && type == 'native'" />
		
		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>


<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		//TODO: Obsolete. Remove.
		initialValue: {
			type: String
		},


		start: {
			type: String,
			default: "00:00"
		},
		
		end: {
			type: String,
			default: "00:15"
		},
		
		step: {
			type: String,
			default: "24:00"
		},



		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: true
        },

		type: {
			type: String,
			default: "native"
		},


	},





	data()
	{
		return {
			value: "",

			pickerOptions: {
				start: "",
				step: "",
				end: ""
			}
		};
	},






	mounted()
	{
		//TODO: Obsolete. Remove.
		// this.value = this.initialValue;
	},

	watch: {
		//TODO: Obsolete. Remove.
		// initialValue: function(to, from)
		// {
		// 	this.value = this.initialValue;
		// }
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		//TODO: Obsolete. Remove.
		onClick()
		{
			this.$emit('click', {});
		},




		// onInputNative($event)
		// {
		// 	//this.$emit('input', $event.target.value);
		// },

		onInputNative(event)
		{
			//console.log("onInput(): ", event.target.value);

			// this.$emit('input', $event.target.value);
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},


		// onChangeNative($event)
		// {	
		// 	//this.$emit('change', $event.target.value);

		// 	this.$emit('input', this.value);
		// 	this.$emit('change', this.value);

		// 	this.$emit('update:modelValue', this.value);
		// },


		// onBlurNative($event)
		// {
		// 	this.$emit('blur', $event.target.value);
		// },





		//TODO: Obsolete. Remove.
		// onInputEl(value)
		// {
		// 	//TODO: This zeroes out the bound model value.
		// 	// console.log("time.onInputEl(): value=", value);
		// 	// console.log("time.onInputEl(): this.value=", this.value);

		// 	// this.$emit('input', value);

		// },

		// onBlurEl($event)
		// {
		// 	// console.log("this.onBlurEl(): $event=", $event);

		// 	this.$emit('blur', $event.value);
		// },

		// onChangeEl(value)
		// {
		// 	// console.log("time.onChangeEl(): value=", value);
		// 	// console.log("time.onChangeEl(): this.value=", this.value);

		// 	this.$emit('input', this.value);
		// 	this.$emit('change', this.value);

		// 	this.$emit('update:modelValue', this.value);
		// },

		
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.c-input-time 
	{
		// dsfsdf
	}


	// Grabbed this styling from the <el-time-select> element so keep it consistent.
	.c-input-time-native
	{
		-webkit-appearance: none;
		background-color: #FFF;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		font-size: inherit;
			font-size: 14px;
		font-family: inherit;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		-webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
		transition: border-color .2s cubic-bezier(.645,.045,.355,1);
		/*width: 100%;*/
	}


	// Disabled styling same as Element UI on native time picker
	.c-input-time-native:disabled
	{
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
		cursor: not-allowed;
	}

</style>
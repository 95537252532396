<template>
	<span class="simulate-form-control" style="display: inline-block;">
		<input type="date" 
			:id="id" 
			:name="getName" 
			:class="['c-input-date', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:value="modelValue"
			@input="onInput"
			v-if="type == 'native'">

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin],


	emits: ["change", "input", "blur", "update:modelValue"],

	props: {

		//TODO: Obsolete. Remove.
		initialValue: {
			type: String,
			default: ""
		},

		//TODO: Obsolete. Remove.
		/**
		 * Set this from the parent page/component when wanting to 
		 * programatically update the selected value.
		 */
		updateValue: {
			type: String,
		},


		type: {
			type: String,
			default: "native"
		},



		//TODO: New with Vue 3
        modelValue: {
            type: [String, null],
            default: '',
            required: true
        },

	},





	data()
	{
		return {
			value: ""
		};
	},






	created()
	{
		//TODO: Obsolete. Remove.
		// this.value = this.initialValue;
	},


	watch: {
		//TODO: Obsolete. Remove.
		// initialValue: function(to, from)
		// {
		// 	this.value = this.initialValue;
		// },

		//TODO: Obsolete. Remove.
		// updateValue: function(to, from)
		// {
		// 	//console.log("c-input-date.watch(updateValue): from=", from, "to=", to); 

		// 	this.value = this.updateValue;
		// }
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		//TODO: Obsolete. Remove.
		onClick()
		{
			this.$emit('click', {});
		},







		//TODO: Obsolete. Remove.
		// onInput($event)
		// {
		// 	// this.$emit('input', $event.target.value);

		// 	// this.$emit('update:modelValue', this.value);
		// },

		onInput(event)
		{
			//console.log("onInput(): ", event.target.value);

			// this.$emit('input', $event.target.value);
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},


		//TODO: Obsolete. Remove.
		// onChange($event)
		// {
		// 	//this.$emit('change', $event.target.value);

		// 	this.$emit('input', this.value);
		// 	this.$emit('change', this.value);

		// 	this.$emit('update:modelValue', this.value);
		// },


		
		//TODO: Obsolete. Remove.
		// onBlur($event)
		// {
		// 	// this.$emit('blur', $event.target.value);
		// },










		//TODO: Obsolete. Remove.
		onInputEl(value)
		{
			// //TODO: This zeroes out the bound model value.
			// // console.log("onInputEl(): ", value);

			// // this.$emit('input', value);

			// //this.$emit('update:modelValue', value);

			// this.$emit('update:modelValue', this.value);

			
		},

		onBlurEl($event)
		{
			// console.log("onBlurEl(): ", $event);

			// this.$emit('blur', $event.target.value);
		},

		onChangeEl(value)
		{
			console.log("c-input-date:onChangeEl(): this.value=", this.value);

			this.$emit('input', this.value);		//TODO: This is needed to bind back to the model
			this.$emit('change', this.value);

			this.$emit('update:modelValue', this.value);
		},



	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";



</style>
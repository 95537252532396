const globalStore = {
	state: {
		/**
		 * Keys used to persist data to localStorage
		 */
		localStorageKeys: {
			currentUser: "Lotus:CurrentUser"
		},
		//Page/Component level keys start with "Component:{id==some-name-we-need-to-make-sure-is-not-duplicated-across-components}:{key==whatever-component-level-key-we-want}"


		currentUser: {

			/**
			 * User's authentication token after they login.
			 * Send with every API request.
			 */
			authToken: "",

			userId: "",
			activeAccountId: "",


			// When the user is a client accessing a client portal (e.g. Tessta Client Portal)
			activeClientId: "",
			activeClientDisplayName: "",


			completeAccessStructure: {},

			firstName: "",
			lastName: "",
			displayName: "",
			
			isLotusAccount: false,

			isRememberMe: false,	// Is the user's details being stored to localStorage for future sessions?

			isAliasLogin: false,
			lotusUserId: ""
		},


		app: {
			completeAccessStructureForUserLastRetrievedUtc: null
		},


		// Path to redirect to after logging in, if coming to the specified page but not yet logged in (ie. the "redirect=" type path)
		redirectAfterLoginPath: "",


		//pageLayoutClass: "container-fluid",
		pageLayoutClass: "container",


		/**
		 * For poping data between components where emitting events doesn't play nicely (e.g. user ID from timesheet back to parent layout then into sidebar components)
		 */
		pipe: {
			timesheetUserId: ""
		},


		systemAdminSelectedAccountId: ""
	},



	localStorage: {

		/**
		 * Save data to localStorage by the specified key.  
		 * Takes any type/structure/value of data and `JSON.stringify` it before saving.
		 * 
		 * @param {string} key The key to save to localStorage.
		 * @param {any} value The data to save to localStorage.
		 * @returns void
		 */
		saveDataToStorage(key, value)
		{
			if (value == null)
			{
				// Remove the item
				localStorage.removeItem(key);
				return;
			}


			// console.log("globalStore.localStorage.saveDataToStorage(): ", key, value);

			var json = this.dataToJson(value);

			localStorage[key] = json;
		},



		dataToJson(data, pretty = false)
		{
			var json = "";

			if (pretty == true)
			{
				json = JSON.stringify(data, null, 3);
			}
			else
			{
				// Minimal for storage
				json = JSON.stringify(data);
			}

			return json;
		},




		/**
		 * Return data from localStorage by the specified key.
		 * 
		 * @param {string} key The key to retrieve from localStorage.
		 * @returns The saved data - already hydrated with `JSON.parse()` - if it exists.  
		 * Otherwise `null`.
		 */
		getDataFromStorage(key)
		{
			var storedData = localStorage[key];

			if (storedData == undefined)
			{
				return null;
			}
			storedData = JSON.parse(storedData);

			return storedData;
		}
	},





	systemAdmin: {
		setSelectedAccountId(accountId)
		{
			globalStore.state.systemAdminSelectedAccountId = accountId;
		}
	},





	currentUser: {
		setCurrentUser(authToken,
			userId,
			firstName,
			lastName,
			displayName,
			activeAccountId,
			activeClientId,
			activeClientDisplayName,
			completeAccessStructure,	//accessProfiles,
			isLotusAccount,
			rememberMe)
		{
			//console.log("globalStore.currentUser.setCurrentUser(): completeAccessStructure=", completeAccessStructure);


			globalStore.state.currentUser.authToken = authToken;
			globalStore.state.currentUser.userId = userId;
			globalStore.state.currentUser.firstName = firstName;
			globalStore.state.currentUser.lastName = lastName;
			globalStore.state.currentUser.displayName = displayName;
			globalStore.state.currentUser.activeAccountId = activeAccountId;

			globalStore.state.currentUser.activeClientId = activeClientId;
			globalStore.state.currentUser.activeClientDisplayName = activeClientDisplayName;

			globalStore.state.currentUser.isLotusAccount = (isLotusAccount === true ? true : false);	// Terniary in case the value hasn't been previously stored and is undefined.

			globalStore.state.currentUser.completeAccessStructure = completeAccessStructure;

			globalStore.state.currentUser.isRememberMe = rememberMe;

			// Save to local storage. NOTE: Check is done within the method to ensure saving is allowed.
			globalStore.currentUser.rememberCurrentUser();

			//console.log("globalStore.currentUser.setCurrentUser(): globalStore.state.currentUser=", globalStore.state.currentUser);
		},


		/**
		 * When a Lotus admin aliases as another user, identify this as an alias session and set the ID of the Lotus user doing the work.
		 * @param {string} lotusUserId User.ID of the Lotus user who is aliasing the current user.
		 */
		setCurrentUserAlias(lotusUserId)
		{
			//console.log("setCurrentUserAlias(): lotusUserId=", lotusUserId);
			
			globalStore.state.currentUser.isAliasLogin = true;
			globalStore.state.currentUser.lotusUserId = lotusUserId;

			// Save to local storage. NOTE: Check is done within the method to ensure saving is allowed.
			globalStore.currentUser.rememberCurrentUser();
		},



		setCurrentUserCompleteAccessStructure(completeAccessStructure)
		{
			//console.log("globalStore.currentUser.setCurrentUserCompleteAccessStructure(): completeAccessStructure=", completeAccessStructure);

			globalStore.state.currentUser.completeAccessStructure = completeAccessStructure;
			
			// Save to local storage
			globalStore.localStorage.saveDataToStorage(globalStore.state.localStorageKeys.currentUser, globalStore.state.currentUser);
		},


		rememberCurrentUser()
		{
			// Ensure we'r allowed to save data based on the user's remember me selection
			if (globalStore.state.currentUser.isRememberMe == false) return;	// Not remembering


			//NOTE: Obsolete. clearCurrentUser() should be called explicitly where needed to avoid accidental clears.
			// Clear the current user first. Consider this a reset.
			//globalStore.currentUser.clearCurrentUser();

			// Persist to localStorage to remember next load.
			globalStore.localStorage.saveDataToStorage(globalStore.state.localStorageKeys.currentUser, globalStore.state.currentUser);
		},



		resetCurrentUserDataStructure()
		{
			globalStore.state.currentUser.authToken = "";
			globalStore.state.currentUser.userId = "";
			globalStore.state.currentUser.firstName = "";
			globalStore.state.currentUser.lastName = "";
			globalStore.state.currentUser.displayName = "";
			globalStore.state.currentUser.activeAccountId = "";
			globalStore.state.currentUser.activeClientId = "";
			globalStore.state.currentUser.activeClientDisplayName = "";
			globalStore.state.currentUser.isLotusAccount = false;

			globalStore.state.currentUser.isRememberMe = false;

			globalStore.state.currentUser.isAliasLogin = false;
			globalStore.state.currentUser.lotusUserId = "";
			
			globalStore.state.currentUser.completeAccessStructure = {};
		},


		clearCurrentUser()
		{
			console.log("clearCurrentUser()");

			globalStore.currentUser.resetCurrentUserDataStructure();

			globalStore.localStorage.saveDataToStorage(globalStore.state.localStorageKeys.currentUser, null);
		},


		//TODO: Note - this is getting called multiple times (via app.js) for some reason. Be sure to clear arrays before loading each time.
		/**
		 * Load the current user's details from local storage.
		 */
		loadCurrentUser()
		{
			// Get details from storage
			var storedDetails = globalStore.localStorage.getDataFromStorage(globalStore.state.localStorageKeys.currentUser);

			//console.log("globalStore.currentUser.loadCurrentUser(): storedDetails=", storedDetails);

			if (storedDetails == null || storedDetails == undefined)
			{
				// No details currently stored.
				return;
			}

			//TODO: That just
			// Mainly to make sure arrays are cleared if this function is called multiple times om a page/route load.
			globalStore.currentUser.resetCurrentUserDataStructure();

			// Populate the data store.
			// (rememberMe = false because it's already in localStorage)
			globalStore.currentUser.setCurrentUser(storedDetails.authToken,
				storedDetails.userId,
				storedDetails.firstName,
				storedDetails.lastName,
				storedDetails.displayName,
				storedDetails.activeAccountId,
				storedDetails.activeClientId,
				storedDetails.activeClientDisplayName,
				storedDetails.completeAccessStructure,
				storedDetails.isLotusAccount,
				storedDetails.isRememberMe);

			if (storedDetails.isAliasLogin)
			{
				globalStore.currentUser.setCurrentUserAlias(storedDetails.lotusUserId);
			}
			
		},


		/**
		 * Check if the current user is logged in.
		 */
		isLoggedIn()
		{
			// Has a UserID means logged in.
			// console.log("globalStore.currentUser.isLoggedIn(): globalStore.state=", globalStore.state);
			// console.log("globalStore.currentUser.isLoggedIn(): globalStore.state.currentUser.userId=", JSON.stringify(globalStore.state.currentUser));
			return globalStore.state.currentUser.userId != "";
		},



		hasFeatureAccess(featureCode)
		{
			// if (!globalStore.state.currentUser.completeAccessStructure) return false;

			// Default to false.
			var result = false;

			// Data structure not creatd yet.
			if (!globalStore.state.currentUser.completeAccessStructure) return false;
			if (!globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags) return false;

			//console.log("hasFeatureAccess(" + featureCode + "): globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags=", globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags);

			globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags.forEach( flag => {
				// console.log("flag for '" + featureCode + "': ", flag);
				if (flag.FeatureCode.toLowerCase() == featureCode.toLowerCase())
				{
					//console.log("found!!!!", flag);

					//TODO: 2022-04-24: "Granted" is replacing "Enabled". "Enabled" is retained for the transition period.
					if (flag.Access == "Enabled" || flag.Access == "Granted") 
					{
						result = true;
						return;	// Exit the forEach()
					}

					// Otherwise Access == "Disabled"
					return;	// Exit the forEach. `result` will remain the default false.
				}
			});

			
			return result;

		},


	},





	helpers: {

		/**
		 * Returns a JavaScript date representing today (for the user's browser date).  
		 * Could be manually set to a specific date for testing locally.
		 */
		getTodayDate()
		{
			return new Date();
			//return new Date(2021, 10, 16);	// 16 Nov 2021 (remember moth is zero based for some absurd fucking reasons only the idiot who designed Date() willl ever know)
		},



		/**
		 * Does the user's Account have a feature - `featureCode` - enabled?
		 * NOTE: this is derived from `globalStore.currentUser.hasFeatureAccess(featureCode)` [above].
		 */
		hasAccountFeature(featureFlagsSet, featureCode)
		{
			// if (!globalStore.state.currentUser.completeAccessStructure) return false;

			// console.log(">>>>>> hasAccountFeature(): featureFlagsSet=", featureFlagsSet);

			// Default to false.
			var result = false;

			if (!featureFlagsSet) return false;

			//console.log("hasFeatureAccess(" + featureCode + "): globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags=", globalStore.state.currentUser.completeAccessStructure.FeatureAccessFlags);

			featureFlagsSet.forEach( flag => {
				// console.log("flag for '" + featureCode + "': ", flag);
				if (flag.FeatureCode.toLowerCase() == featureCode.toLowerCase())
				{
					//console.log("found!!!!", flag);

					//TODO: 2022-04-24: "Granted" is replacing "Enabled". "Enabled" is retained for the transition period.
					if (flag.Access == "Enabled" || flag.Access == "Granted") 
					{
						result = true;
						return;	// Exit the forEach()
					}

					// Otherwise Access == "Disabled"
					return;	// Exit the forEach. `result` will remain the default false.
				}
			});

			
			return result;

		},
	}
};





export default globalStore;